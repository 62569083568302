import React, { Component } from 'react'
import StrainForm from './strain.form'
import StrainModel from './strain.model'
class StrainCreate extends Component {
  render () {
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <StrainForm
            handleSubmit={values => StrainModel.create(values, this.props.history)}
            formState='create'
            history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default StrainCreate
