import React from 'react'
import Navbar from './navbar'
import Sidebar from './sidebar'
import MainContent from './main.content'
import Alert from './appwide.alert'
import { Switch } from 'react-router-dom'

const Layout1 = (props) => (
  <div>
    {props.children}
  </div>
)

export default Layout1
