import React, { Component } from 'react'
import BrandForm from './brand.form'
import BrandModel from './brand.model'

class BrandView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brand: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    const brand = await BrandModel.getAndPrepareForView(id)
    this.setState({ brand: brand })
  }

  render () {
    console.log(this.state.brand)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <BrandForm initialValues={this.state.brand} formState='view' id={this.props.match.params.id} history={this.props.history} />
        </div>
      </div>
    )
  }
}

export default BrandView
