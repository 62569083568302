import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class CSVUploadModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      file: {}
    }
  }
  handleSubmit (e) {
    e.preventDefault()
    console.log('ey')
    const reqBod = {
      csv: this.state.file.file
    }
    this.props.uploadCSV(reqBod)
  }
  handleFileUpload (e) {
    if (e.target.files[0]) {
      console.log(e.target.files[0].name.split('.').pop())
      if (e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel' && (e.target.files[0].name.split('.').pop() !== 'csv')) {
        alert('File must be a csv')
        e.target.value = null
        e.target.files = null
        return
      }
      const fr = new FileReader()
      fr.onload = (e) => {
        console.log('load')
        const cpy = this.state.file
        cpy.file = e.target.result
        console.log(cpy)
        this.setState({ file: cpy })
      }
      fr.onprogress = (e) => {
        console.log('prog')
        const cpy = this.state.file
        cpy.percent = e.loaded / e.total
        this.setState({ file: cpy })
      }
      fr.onerror = (e) => {
        console.log(e)
      }
      fr.readAsText(e.target.files[0])

      this.setState({ file: {
        elem: e.target.files[0],
        file: '',
        fileName: e.target.files[0].name,
        percent: 0
      } })
    }
  }
  render () {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Upload CSV</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <input className='form-control-file' type='file' onChange={this.handleFileUpload.bind(this)} />
          </div>
          {
            this.state.file.fileName ?
              <div className='progress'>
                <div
                  className={this.state.file.percent === 1 ? 'progress-bar bg-success' : 'progress-bar'}
                  role='progressbar'
                  style={{width: parseInt(this.state.file.percent * 100) + '%'}}
                  aria-valuenow={parseInt(this.state.file.percent * 100) + '%'}
                  aria-valuemin='0' aria-valuemax='100'>
                  {this.state.file.percent === 1 ? 'Ready to upload!' : parseInt(this.state.file.percent * 100) + '%'}
                </div>
              </div> : ''
          }
          <div className='text-danger'>
            {this.props.err}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={this.handleSubmit.bind(this)}>Submit</Button>{' '}
          <Button color='secondary' onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default CSVUploadModal
