import React, { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import LoginSchema from './login.schema'
import AuthHelpers from '../helpers/auth.helper'

import axios from 'axios'
class Login extends Component {
  handleSubmit (values) {
    console.log('mew')
    axios.post('/api/auth/admin', values)
      .then(resp => {
        this.props.showAlert('alert-success', 'Login Success.', 3000)
        AuthHelpers.setToken(resp.data.token)
        this.props.history.push('/')
      })
      .catch(err => {
        this.props.showAlert('alert-danger', 'Login Failed.', 3000)
        console.error(err.response || err)
      })
  }
  render () {
    console.log(this.props)
    return (
      <Formik
        enableReinitialize='true'
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={this.handleSubmit.bind(this)}
        render={({ errors, touched, setFieldValue, values }) => {
          console.log(touched)
          return (
            <div className='container-fluid'>
              <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-xs-8 offset-xs-2 shadow rounded login-block'>
                <div className='card'>
                  <Form>
                    <div className='card-header'>
                      Sign In
                    </div>
                    <div className='card-body'>
                      <div className='form-group'>
                        <label>E-Mail</label>
                        <Field type='email' className={errors.email && touched.email ? 'form-control is-invalid' : 'form-control'} name='email' />
                        <ErrorMessage
                          name='email'
                          component='small'
                          className='invalid-feedback'
                        />
                      </div>
                      <div className='form-group'>
                        <label>Password</label>
                        <Field type='password' className={errors.password && touched.password ? 'form-control is-invalid' : 'form-control'} name='password' />
                        <ErrorMessage
                          name='password'
                          component='small'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button type='submit' className='btn btn-primary float-right'>Submit</button>
                      <div className='clearfix' />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}
export default Login
