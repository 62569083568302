import * as Yup from 'yup'

const BrandSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is Required'),
  email: Yup.string()
    .email('Invalid email address'),
  contact_email: Yup.string()
    .email('Invalid email address'),
  rating: Yup.number('Must be a valid number'),
  popularity: Yup.number('Must be a valid number'),
  short_desc: Yup.string(),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string(),
  country: Yup.string(),
  phone: Yup.string(),
  website: Yup.string(),
  contact_name: Yup.string(),
  contact_phone: Yup.string(),
  license: Yup.string()
})

export default BrandSchema
