import React, { Component } from 'react'

class AppwideAlert extends Component {
  render () {
    setTimeout(() => this.props.hideAlert(), this.props.duration)
    console.log(this.props)
    return (
      <div className={`alert ${this.props.type} text-center`} style={{ zIndex: 100 }} role='alert'>
        { this.props.msg }
      </div>
    )
  }
}

export default AppwideAlert
