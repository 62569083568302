import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Paginator from '../Paginator'
import CSVUploadModal from '../_common/csv.upload.modal'
import AuthHTTP from '../helpers/auth.http'

class BrandHome extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      rows: [],
      renderedRows: [],
      nameFilter: '',
      count: 0,
      paginationList: '',
      modal: false,
      csvErr: ''
    }
  }
  toggleModal () {
    this.setState({ modal: !this.state.modal })
  }
  handleChange (event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    })
  }
  getBrands () {
    const page = this.state.page || 1
    AuthHTTP.get(`/api/brand/paginated/${page}`)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  componentDidMount () {
    this.getBrands()
  }
  renderRows (rows) {
    return rows.map(row => {
      return (
        <tr key={row.id}>
          <td>{row.id}</td>
          <td>{row.name}</td>
          <td>{row.rating}</td>
          <td>{row.popularity}</td>
          <td>{row.short_desc}</td>
          <td style={{ width: '61px' }}>
            <Link to={`/brand/view/${row.id}`}> <i className='fa fa-eye' aria-hidden='true' /></Link>
            <Link to={`/brand/edit/${row.id}`}> <i className='fa fa-pencil' aria-hidden='true' /></Link>
          </td>
        </tr>
      )
    })
  }
  uploadCSV (csv) {
    AuthHTTP.post('/api/brand/csv', csv)
      .then(resp => {
        console.log(resp)
        this.getBrands()
        this.toggleModal()
      })
      .catch(err => {
        let msg = '';
        console.error(err.response.data)
        // TODO wonky handling fix
        if (err.response.data.message.split('-')[1] === ' duplicate key value violates unique constraint "index_tbl_brand_on_name"') {
          msg = 'Duplicate names found in CSV.'
        }
        this.setState({ csvErr: msg || err.response.data.message })
      })
  }
  retrieveDataAndUpdateState (page = 1) {
    let url = `/api/brand/paginated/${page}`
    if (this.state.nameFilter) url += `/name/${this.state.nameFilter}`
    AuthHTTP.get(url)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  handleSearch (e) {
    if (e) e.preventDefault()
    this.retrieveDataAndUpdateState()
  }

  changePage (page) {
    this.retrieveDataAndUpdateState(page)
  }

  render () {
    // TODO make seperate component
    const paginator = <Paginator page={this.state.page} count={this.state.count} changeHandler={this.changePage.bind(this)} />
    const rowsToShow = this.renderRows(this.state.rows)
    console.log('state', this.state)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2 home-block'>
          <div className='row d-flex justify-content-between'>
            <div className='col-md-5'>
              <form>
                <div className='input-group mb-3'>
                  <input type='text' className='form-control' placeholder={'Search by name...'} name='nameFilter' onChange={this.handleChange.bind(this)}/>
                  <div className='input-group-append'>
                    <button className='btn btn-outline-secondary' type='submit' onClick={this.handleSearch.bind(this)}>Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-md-5'>
              <div className='row'>
                <Link className='btn btn-primary btn-sm' to='/brand/create'>Create Brand</Link>
                <a className='btn btn-primary btn-sm' href='/BrandCreate.csv'>Download CSV</a>
                <button className='btn btn-primary btn-sm' onClick={this.toggleModal.bind(this)}>Upload CSV</button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2'>
              {paginator}
            </div>
          </div>
          <div className={'row'}>
            <table className='table table-bordered text-center'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Rating</th>
                  <th>Popularity</th>
                  <th>Short Desc</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { rowsToShow.length ? rowsToShow : <td>No data to show</td> }
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2 text-center'>
              {paginator}
            </div>
          </div>
        </div>
        <CSVUploadModal toggle={this.toggleModal.bind(this)} open={this.state.modal} uploadCSV={this.uploadCSV.bind(this)} err={this.state.csvErr} />
      </div>
    )
  }
}

export default BrandHome
