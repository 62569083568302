import React, { Component } from 'react'
import BrandForm from './dispensary.form'
import DispensaryModel from './dispensary.model'

class DispensaryView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dispensary: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    const dispensary = await DispensaryModel.getAndPrepareForView(id)
    this.setState({ dispensary: dispensary })
  }

  render () {
    console.log(this.state.brand)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <BrandForm initialValues={this.state.dispensary} formState='view' id={this.props.match.params.id} history={this.props.history} />
        </div>
      </div>
    )
  }
}

export default DispensaryView
