import React, { Component } from 'react'
import BrandForm from './brand.form'
import BrandModel from './brand.model'

class BrandEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brand: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    const brand = await BrandModel.getAndPrepareForView(id)
    this.setState({ brand: brand })
  }

  render () {
    console.log(this.state.brand)
    const id = this.props.match.params.id
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <BrandForm
            handleSubmit={values => BrandModel.edit(values, id, this.props.history)}
            initialValues={this.state.brand} formState='edit' id={id} history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default BrandEdit
