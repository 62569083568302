import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Paginator from '../Paginator'
import AuthHTTP from '../helpers/auth.http'

class DispensaryHome extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      rows: [],
      renderedRows: [],
      nameFilter: '',
      count: 0,
      paginationList: ''
    }
  }
  handleChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }
  componentDidMount () {
    const page = this.state.page || 1
    AuthHTTP.get(`/api/dispensary/paginated/${page}`)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  renderRows (rows) {
    return rows.map(row => {
      return (
        <tr key={row.id} className={row.active ? '' : 'table-secondary'}>
          <td>{row.id}</td>
          <td>{row.name}</td>
          <td>{row.email}</td>
          <td>{row.phone_number}</td>
          <td>{row.address}</td>
          <td style={{ width: '61px' }}>
            <Link to={`/dispensary/view/${row.id}`}> <i className='fa fa-eye' aria-hidden='true' /></Link>
            <Link to={`/dispensary/edit/${row.id}`}> <i className='fa fa-pencil' aria-hidden='true' /></Link>
          </td>
        </tr>
      )
    })
  }
  retrieveDataAndUpdateState (page = 1) {
    let url = `/api/dispensary/paginated/${page}`
    if (this.state.nameFilter) url += `/name/${this.state.nameFilter}`
    AuthHTTP.get(url)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  handleSearch (e) {
    if (e) e.preventDefault()
    this.retrieveDataAndUpdateState()
  }

  changePage (page) {
    this.retrieveDataAndUpdateState(page)
  }

  render () {
    const paginator = <Paginator page={this.state.page} count={this.state.count} changeHandler={this.changePage.bind(this)} />
    const rowsToShow = this.renderRows(this.state.rows)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2 home-block'>
          <div className='row d-flex justify-content-between'>
            <div className='col-md-5'>
              <form>
                <div className='input-group mb-3'>
                  <input type='text' className='form-control' placeholder={'Search by name...'} name='nameFilter' onChange={this.handleChange.bind(this)} />
                  <div className='input-group-append'>
                    <button className='btn btn-outline-secondary' type='submit' onClick={this.handleSearch.bind(this)}>Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-md-5'>
              <Link className='btn btn-primary float-right' to='/dispensary/create'>Create Dispensary</Link>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2'>
              {paginator}
            </div>
          </div>
          <div className={'row'}>
            <table className='table table-bordered text-center'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { rowsToShow.length ? rowsToShow : <tr><td>No data to show</td></tr>}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2 text-center'>
              {paginator}
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DispensaryHome
