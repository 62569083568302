import 'bootstrap/dist/css/bootstrap.min.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './auth/private.route'
import FourOhFour from './404'

import BrandHome from './brands/brand.home'
import BrandCreate from './brands/brand.create'
import BrandView from './brands/brand.view'
import BrandEdit from './brands/brand.edit'

import DispensaryHome from './dispensaries/dispensary.home.js'
import DispensaryCreate from './dispensaries/dispensary.create'
import DispensaryView from './dispensaries/dispensary.view'
import DispensaryEdit from './dispensaries/dispensary.edit'

import StrainHome from './strains/strain.home'
import StrainCreate from './strains/strain.create'
import StrainView from './strains/strain.view'
import StrainEdit from './strains/strain.edit'
import MainContent from './layout/main.content'
import Sidebar from './layout/sidebar'
import Login from './auth/login'
import Home from './home'
import Navbar from './layout/navbar'
import Alert from './layout/appwide.alert'
import ProtectedLayout from './layout/protected.layout'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSidebar: false,
      alert: {}
    }
  }
  toggleShowSidebar () {
    this.setState({ showSidebar: !this.state.showSidebar })
  }
  showAlert (type, msg, duration) {
    console.log(type, msg, duration)
    this.setState({ alert: { type: type, msg: msg, duration: duration } })
  }
  hideAlert () {
    this.setState({ alert: {} })
  }
  render () {
    console.log(this.props)
    return (
      <div className='root'>
        <Router>
          <Switch>
            <Route exact path='/login' component={(props) => <div>{ this.state.alert.msg ? <Alert {...this.state.alert} hideAlert={this.hideAlert.bind(this)} /> : '' }<Login {...props} showAlert={this.showAlert.bind(this)} /></div>} />
            <Route component={ProtectedLayout}>
              <Navbar toggleSidebar={this.toggleShowSidebar.bind(this)} />
              <Sidebar showSidebar={this.state.showSidebar} msg={this.state.alert.msg} type={this.state.alert.type} duration={this.state.alert.duration} />
              { this.state.alert.msg ? <Alert {...this.state.alert} hideAlert={this.hideAlert.bind(this)} /> : '' }
              <MainContent>
                {/* Brands */}
                <Switch>
                  <PrivateRoute path='/brand/home' exact component={BrandHome} showAlert={this.showAlert.bind(this)} />
                  <PrivateRoute path='/brand/create' exact component={(props) => <BrandCreate {...props} showAlert={this.showAlert.bind(this)} />} />
                  <PrivateRoute path='/brand/view/:id' exact component={(props) => <BrandView {...props} showAlert={this.showAlert.bind(this)} />} />
                  <PrivateRoute path='/brand/edit/:id' exact component={(props) => <BrandEdit {...props} showAlert={this.showAlert.bind(this)} />} />
                  {/* Dispensaries */}
                  <PrivateRoute path='/dispensary/home' exact component={DispensaryHome} />
                  <PrivateRoute path='/dispensary/create' exact component={DispensaryCreate} />
                  <PrivateRoute path='/dispensary/view/:id' exact component={DispensaryView} />
                  <PrivateRoute path='/dispensary/edit/:id' exact component={DispensaryEdit} />
                  {/* Strains */}
                  <PrivateRoute path='/strain/home' exact component={StrainHome} />
                  <PrivateRoute path='/strain/create' component={StrainCreate} />
                  <PrivateRoute path='/strain/view/:id' exact component={StrainView} />
                  <PrivateRoute path='/strain/edit/:id' component={StrainEdit} />
                  {/* other */}
                  <PrivateRoute path='/' exact component={Home} showAlert={this.showAlert.bind(this)} />
                  <Route path='/404' exact component={FourOhFour} />
                  <PrivateRoute component={FourOhFour} />
                </Switch>
              </MainContent>
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App
