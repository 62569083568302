import React, { Component } from 'react'
import DispensaryForm from './dispensary.form'
import DispensaryModel from '../dispensaries/dispensary.model'
class DispensaryEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dispensary: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    const dispensary = await DispensaryModel.getAndPrepareForView(id)
    this.setState({ dispensary: dispensary })
  }

  render () {
    const id = this.props.match.params.id
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <DispensaryForm
            handleSubmit={values => DispensaryModel.edit(values, id, this.props.history)}
            initialValues={this.state.dispensary} formState='edit' id={id} history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default DispensaryEdit
