import axios from 'axios'
import AuthHelpers from './auth.helper'


const AuthHTTP = axios.create({})
AuthHTTP.interceptors.request.use(function (config) {
  config.headers.Authorization = AuthHelpers.getToken()
  return config
})
AuthHTTP.interceptors.response.use(null, function (error) {
  // Do something with response error
  console.log(error)
  if (error.response) {
    if (error.response.status === 401) {
      console.log('hello')
      AuthHelpers.logout()
    }
  }
  return Promise.reject(error)
})
export default AuthHTTP
