import * as Yup from 'yup'

const DispensarySchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is Required'),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string(),
  phone_number: Yup.string(),
  website_url: Yup.string(),
  latitude: Yup.number(),
  longtitude: Yup.number(),
  email: Yup.string()
    .email('Invalid email address'),
  delivery_name: Yup.string(),
  working_hours: Yup.string(),
  is_premiere: Yup.bool(),
  cannabiz_id: Yup.string(),
  store_type: Yup.string(),
  twitter: Yup.string(),
  facebook: Yup.string(),
  leafly: Yup.string(),
  weedmaps: Yup.string(),
  allbud: Yup.string(),
  country_code: Yup.string(),
  google: Yup.string(),
  licensed_id: Yup.string(),
  licensed_description: Yup.string(),
  active: Yup.bool(),
  pos_key: Yup.string(),
  pos: Yup.string(),
  priority: Yup.number()
})

export default DispensarySchema
