import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import AuthHelpers from '../helpers/auth.helper'

const Navbar = props => {
  const loggedIn = AuthHelpers.isLoggedIn()
  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-primary sticky-top'>
      <Link className='navbar-brand' to='/'>CannaAdmin</Link>
      <button className='btn btn-primary ml-auto' onClick={e => AuthHelpers.logout(props.history)} hidden={!loggedIn}>
        <i className='fa fa-2x fa-sign-out' aria-hidden='true' />
      </button>
      <button className='navbar-toggler' type='button' onClick={props.toggleSidebar}>
        <span className='navbar-toggler-icon' />
      </button>
    </nav>
  )
}
export default withRouter(Navbar)
