import React, { Component } from 'react'
import DispensarySchema from './dispensary.schema'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import Async from 'react-select/lib/Async'
import AuthHTTP from '../helpers/auth.http'

class DispensaryForm extends Component {
  async loadBrandSelect (i) {
    const resp = await AuthHTTP.get(`/api/brand?col=name&q=${i || ''}`)
    console.log(resp)
    return resp.data.map(e => ({ label: e.name, value: e }))
  }
  async loadStrainSelect (selected, i) {
    console.log(i)
    console.log(selected)
    const resp = await AuthHTTP.get(`/api/strain?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.strain_id)
    console.log(selectedIds)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }
  updateStockQuantity (e, index, setFieldValue, strainDispensaries) {
    strainDispensaries[index].changed = true
    strainDispensaries[index].num_in_stock = e.target.value
    setFieldValue('strain_dispensary', strainDispensaries)
  }
  deleteStrainDispensary (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      console.log('hey')
      if (value.strain_id && value.dispensary_id) {
        AuthHTTP.delete(`/api/strain_dispensary/${value.strain_id}/${value.dispensary_id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  delete (e) {
    e.preventDefault()
    AuthHTTP.delete(`/api/dispensary/${this.props.id}`)
      .then(resp => {
        console.log(resp)
        this.props.history.push('/dispensary/home')
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  handleFileUpload (e, values, setFieldValue) {
    const mainEvent = e
    mainEvent.persist()
    if (e.target.files[0]) {
      const index = values.photos.length
      console.log(e.target.files[0])
      values.photos.push({
        new: true,
        file: '',
        fileName: e.target.files[0].name,
        percent: 0
      })
      const fr = new FileReader()
      fr.onload = (e) => {
        values.photos[index].file = e.target.result
        mainEvent.target.value = null
        mainEvent.target.files = null
        setFieldValue('photos', values.photos)
      }
      fr.onprogress = (e) => {
        values.photos[index].percent = e.loaded / e.total
        setFieldValue('photos', values.photos)
      }
      fr.onerror = (e) => {
        console.log(e)
      }
      fr.readAsDataURL(e.target.files[0])
    }
  }
  deletePhoto (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      console.log('hey')
      if (value.id) {
        AuthHTTP.delete(`/api/photo/${value.id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  render () {
    const isView = this.props.formState === 'view'
    const isEdit = this.props.formState === 'edit'
    const isAdd = this.props.formState === 'create'
    const showDeleteAndEdit = this.props.formState === 'edit' || this.props.formState === 'view'
    const showSubmit = this.props.formState === 'edit' || this.props.formState === 'create'
    console.log(this.props.initialValues)
    return (<Formik
      enableReinitialize='true'
      initialValues={this.props.initialValues || {
        name: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        phone_number: '',
        website_url: '',
        latitude: undefined,
        longtitude: undefined,
        email: '',
        delivery_name: '',
        working_hours: '',
        description: '',
        is_premiere: false,
        canabiz_id: '',
        store_type: '',
        twitter: '',
        facebook: '',
        leafly: '',
        weedmaps: '',
        allbud: '',
        country_code: 'usa',
        google: '',
        licensed_id: '',
        licensed_description: '',
        active: true,
        pos_key: '',
        pos: '',
        priority: 2.5,
        brand: { label: '', value: { id: undefined } },
        strain_dispensary: [],
        photos: []
      }}
      validationSchema={DispensarySchema}
      onSubmit={this.props.handleSubmit}
      render={({ errors, touched, values, setFieldValue }) => (
        <Form className='form-block'>
          <div className='row d-flex justify-content-between'>
            <div className='col-xs-1 ml-1'>
              <Link to={'/dispensary/home'}><i className='fa fa-chevron-left' aria-hidden='true' /></Link>
            </div>
            <div className='col-xs-1 offset-md-10'>
              { isView ? <Link to={`/dispensary/edit/${this.props.id}`}><i className='fa fa-2x fa-pencil-square-o' aria-hidden='true' /></Link> : '' }
            </div>
          </div>

          <div className='form-group'>
            <label>Dispensary Name <span className='req-ast'>*</span></label>
            <Field type='text' className='form-control' name='name' disabled={isView} />
            <ErrorMessage
              name='name'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Dispensary Address</label>
            <Field type='text' className='form-control' name='address' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary City</label>
            <Field type='text' className='form-control' name='city' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary State</label>
            <Field type='text' className='form-control' name='state' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Zipcode</label>
            <Field type='text' className='form-control' name='zipcode' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Phone Number</label>
            <Field type='text' className='form-control' name='phone_number' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Website</label>
            <Field type='text' className='form-control' name='website_url' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Latitude</label>
            <Field type='number' className='form-control' name='latitude' disabled={isView} />
            <ErrorMessage
              name='latitude'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Dispensary Longtitude</label>
            <Field type='number' className='form-control' name='longtitude' disabled={isView} />
            <ErrorMessage
              name='longtitude'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Dispensary Email</label>
            <Field type='email' className='form-control' name='email' disabled={isView} />
            <ErrorMessage
              name='email'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Delivery Name</label>
            <Field type='text' className='form-control' name='delivery_name' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Working Hours</label>
            <Field type='text' className='form-control' name='working_hours' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Description</label>
            <Field type='text' className='form-control' name='description' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Premiere</label>
            <Field type='checkbox' className='form-control' name='is_premiere' checked={values.is_premiere} disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Canabiz ID</label>
            <Field type='text' className='form-control' name='canabiz_id' disabled={true} />
          </div>

          <div className='form-group'>
            <label>Store Type</label>
            <Field type='text' className='form-control' name='store_type' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Twitter Info</label>
            <Field type='text' className='form-control' name='twitter' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Facebook Info</label>
            <Field type='text' className='form-control' name='facebook' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Leafly Info</label>
            <Field type='text' className='form-control' name='leafly' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Weedmaps Info</label>
            <Field type='text' className='form-control' name='weedmaps' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Allbud Info</label>
            <Field type='text' className='form-control' name='allbud' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Country Code</label>
            <Field type='text' className='form-control' name='country_code' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Google Info</label>
            <Field type='text' className='form-control' name='google' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Licensed ID</label>
            <Field type='text' className='form-control' name='licensed_id' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Dispensary Licensed Description</label>
            <Field type='text' className='form-control' name='licensed_description' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Active</label>
            <Field type='checkbox' className='form-control' name='active' checked={values.active} disabled={isView} />
          </div>

          <div className='form-group'>
            <label>POS Key</label>
            <Field type='text' className='form-control' name='pos_key' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>POS</label>
            <Field type='text' className='form-control' name='pos' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Priority <small>1 is lower priority than 2</small></label>

            <Field type='number' className='form-control' name='priority' disabled={isView} />
            <ErrorMessage
              name='priority'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand</label>
            <Async
              loadOptions={this.loadBrandSelect.bind(this)}
              isClearable
              value={values.brand}
              onChange={(brand, e) => {
                if (e === 'clear') {
                  setFieldValue('brand', { label: '', value: { id: undefined } })
                } else {
                  setFieldValue('brand', brand)
                }
              }}
              isDisabled={isView}
            />
          </div>
          <div className='form-group'>
            <label>Strains in Stock</label>
            <Async
              isClearable
              loadOptions={this.loadStrainSelect.bind(this, values.strain_dispensary)}
              value={values.selected}
              onChange={(value, e) => {
                const strainDispensary = {}
                if (value) {
                  strainDispensary.name = value.label
                  strainDispensary.dispensary_id = this.props.id
                  strainDispensary.strain_id = value.value.id
                  strainDispensary.new = true
                  strainDispensary.num_in_stock = 1

                  values.strain_dispensary.push(strainDispensary)
                  console.log(values.strain_dispensary)
                  setFieldValue('strain_dispensary', values.strain_dispensary)
                  console.log('oi')
                  setFieldValue('selected', '')
                }
              }}
              isDisabled={isView}
            />
            <div className='row'>
              {
                values.strain_dispensary.length > 0
                  ? <table className='table'>
                    <thead>
                      <tr>
                        <th>Strain Name</th>
                        <th>Number in stock</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        values.strain_dispensary.map((strain, i) =>
                          <tr key={i + strain.name}>
                            <td>{strain.name}</td>
                            <td>
                              <input
                                type='number'
                                className='form-control'
                                value={strain.num_in_stock}
                                onChange={(e) => this.updateStockQuantity(e, i, setFieldValue, values.strain_dispensary)}
                                disabled={isView}
                              />
                            </td>
                            <td>
                              <button type='button' className='btn btn-link' onClick={() => {
                                this.deleteStrainDispensary(strain, () => {
                                  values.strain_dispensary.splice(i, 1)
                                  setFieldValue('strain_dispensary', values.strain_dispensary)
                                })
                              }}>
                                <i className='fa fa-trash' aria-hidden='true' />
                              </button>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                  : ''
              }
            </div>
          </div>
          <div className='form-group'>
            <label>Photos</label>
            <input type='file' className='form-control' onChange={e => this.handleFileUpload(e, values, setFieldValue)} disabled={!isEdit} />
            {
              !isAdd
                ? values.photos.map((p, i) => <div key={i + Date.now()}>
                  <div className='embed-responsive embed-responsive-1by1'>
                    <img className='embed-responsive-item' src={p.file} />
                  </div>
                  {
                    p.new ? <div className='progress'>
                      <div
                        className={p.percent === 1 ? 'progress-bar bg-success' : 'progress-bar'}
                        role='progressbar'
                        style={{ width: parseInt(p.percent * 100) + '%' }}
                        aria-valuenow={parseInt(p.percent * 100) + '%'}
                        aria-valuemin='0' aria-valuemax='100'>
                        {p.percent === 1 ? 'Ready to upload!' : parseInt(p.percent * 100) + '%'}
                      </div>
                    </div> : ''
                  }
                  <a className='btn btn-danger' onClick={() => {
                    this.deletePhoto(p, () => {
                      values.photos.splice(i, 1)
                      setFieldValue('photos', values.photos)
                    })
                  }}>
                    Delete Above Photo
                  </a>
                </div>)
                : <p>Please save the Dispensary to add Photos</p>
            }
          </div>

          {showSubmit ? <button className='btn btn-primary ml-auto p-2' type='submit' >Submit</button> : ''}
          {showDeleteAndEdit ? <button className='btn btn-danger ml-auto p-2' onClick={this.delete.bind(this)}>Delete</button> : ''}
        </Form>
      )}
    />)
  }
}

export default DispensaryForm
