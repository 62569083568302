import FormHelpers from '../helpers/form.helpers'
import AuthHTTP from '../helpers/auth.http'
const { clean, stripEmptyFields, asCallback } = FormHelpers

class DispensaryModel {
  static _prepareForAPI (values) {
    const postableDispensary = JSON.parse(JSON.stringify(values))
    const photos = values.photos.filter(p => p.new).map(p => p.file)

    if (postableDispensary.brand) {
      postableDispensary.brand_id = postableDispensary.brand.value.id || null
    }
    else {
      postableDispensary.brand_id = null
    }
    delete postableDispensary.brand
    delete postableDispensary.selected

    postableDispensary.photos = photos
    console.log(postableDispensary)
    stripEmptyFields(postableDispensary)
    return postableDispensary
  }
  static async getAndPrepareForView (id) {
    const [err, resp] = await asCallback(AuthHTTP.get(`/api/dispensary/${id}`))
    if (err) {
      console.error(err)
      return null
    }
    if (resp.data.rows) {
      // set keys to not be null
      const dispensary = resp.data.rows[0]
      for (let key in dispensary) {
        if (dispensary[key] === null) {
          dispensary[key] = undefined
        }
      }
      if (dispensary.brand) {
        dispensary.brand = {
          label: dispensary.brand.name, value: dispensary.brand
        }
      }
      if (!dispensary.photos) {
        dispensary.photos = []
      }

      return dispensary
    }
  }
  static create (values, history) {
    const dispensary = DispensaryModel._prepareForAPI(values)
    console.log(dispensary)
    AuthHTTP
      .post('/api/dispensary', dispensary)
      .then(resp => {
        history.push(
          `/dispensary/view/${resp.data.createdId}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  static edit (values, id, history) {
    console.log(values)
    const dispensary = DispensaryModel._prepareForAPI(values)
    AuthHTTP
      .put(`/api/dispensary/${id}`, dispensary)
      .then(resp => {
        history.push(
          `/dispensary/view/${id}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
}
export default DispensaryModel
