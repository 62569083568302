import React, { Component } from 'react'
import formHelpers from './helpers/form.helpers'

class Paginator extends Component {
  render () {
    return (
      <nav>
        <ul className='pagination justify-content-center'>
          {formHelpers.pagination(this.props.page, this.props.count, this.props.changeHandler)}
        </ul>
      </nav>
    )
  }
}

export default Paginator
