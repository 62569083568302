import React, { Component } from 'react'
import BrandForm from './brand.form'
import BrandModel from './brand.model'
class BrandCreate extends Component {
  render () {
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <BrandForm
            handleSubmit={values => BrandModel.create(values, this.props.history)}
            formState='create' history={this.props.history}
          />
        </div>

      </div>
    )
  }
}

export default BrandCreate
