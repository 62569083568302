import React, { Component } from 'react'
import StrainSchema from './strain.schema'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import Select, { Async } from 'react-select'
import AuthHTTP from '../helpers/auth.http'

const strainTypeOptions = [
  { value: 'indica', label: 'indica' },
  { value: 'sativa', label: 'sativa' },
  { value: 'hybrid', label: 'hybrid' }
]

class StrainForm extends Component {
  /**
   *
   * @param e
   * @param index
   * @param setFieldValue
   * @param values
   * @param fieldToUpdate
   */
  updatePercentage (e, index, setFieldValue, values, fieldToUpdate) {
    values[index].changed = true
    values[index].percentage = e.target.value
    setFieldValue(fieldToUpdate, values)
  }

  /**
   *
   * @param i - input value
   * @returns {Promise<*>}
   */
  async loadBrandSelect (i) {
    const resp = await AuthHTTP.get(`/api/brand?col=name&q=${i || ''}`)
    console.log(resp)
    return resp.data.map(e => ({ label: e.name, value: e }))
  }

  /**
   *
   * @param selected - currently selected kinds
   * @param i - current input value
   * @returns {Promise<*>}
   */
  async loadKindSelect (selected, i) {
    console.log(i)
    console.log(selected)
    const resp = await AuthHTTP.get(`/api/kind?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.kind_id)
    console.log(selectedIds)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }

  /**
   *
   * @param selected - currently selected kinds
   * @param i - current input value
   * @returns {Promise<*>}
   */
  async loadStrainSelect (selected, i) {
    console.log(i)
    console.log(selected)
    const resp = await AuthHTTP.get(`/api/strain?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.parent_id)
    console.log(selectedIds)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }
  /**
   *
   * @param selected - currently selected kinds
   * @param i - current input value
   * @returns {Promise<*>}
   */
  async loadCannabinoidSelect (selected, i) {
    console.log(i)
    console.log(selected)
    const resp = await AuthHTTP.get(`/api/cannabinoid?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.cannabinoid_id)
    console.log(selectedIds)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }
  async loadTerpeneSelect (selected, i) {
    const resp = await AuthHTTP.get(`/api/terpene?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.terpene_id)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }
  /**
   * Deletes the strain
   * @param e
   */
  delete (e) {
    e.preventDefault()
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (!doIt) return
    console.log('del')
    AuthHTTP
      .delete(`/api/strain/${this.props.id}`)
      .then(resp => {
        console.log(resp)
        this.props.history.push('/strain/home')
      })
      .catch(err => {
        console.error(err.response)
      })
  }

  /**
   *
   * @param value - the object containing the information about the
   * @param removeFromForm
   */
  deleteKindStrain (value, removeFromForm) {
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      if (value.strain_id && value.kind_id) {
        AuthHTTP.delete(`/api/kind_strain/${value.kind_id}/${value.strain_id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  deleteStrainStrain (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      console.log('hey')
      if (value.parent_id && value.child_id) {
        AuthHTTP.delete(`/api/kind_strain/${value.parent_id}/${value.child_id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  deletePhoto (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      console.log('hey')
      if (value.id) {
        AuthHTTP.delete(`/api/photo/${value.id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  deleteCannabinoidStrain (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      if (value.cannabinoid_id && value.strain_id) {
        AuthHTTP.delete(`/api/cannabinoid_strain/${value.cannabinoid_id}/${value.strain_id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  deleteTerpeneStrain (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      if (value.terpene_id && value.strain_id) {
        AuthHTTP.delete(`/api/terpene_strain/${value.terpene_id}/${value.strain_id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  handleFileUpload (e, values, setFieldValue) {
    const mainEvent = e
    mainEvent.persist()
    if (e.target.files[0]) {
      const index = values.photos.length
      console.log(e.target.files[0])
      values.photos.push({
        new: true,
        file: '',
        fileName: e.target.files[0].name,
        percent: 0
      })
      const fr = new FileReader()
      fr.onload = (e) => {
        values.photos[index].file = e.target.result
        mainEvent.target.value = null
        mainEvent.target.files = null
        setFieldValue('photos', values.photos)
      }
      fr.onprogress = (e) => {
        values.photos[index].percent = e.loaded / e.total
        setFieldValue('photos', values.photos)
      }
      fr.onerror = (e) => {
        console.log(e)
      }
      fr.readAsDataURL(e.target.files[0])
    }
  }

  render () {
    const isView = this.props.formState === 'view'
    const isEdit = this.props.formState === 'edit'
    const isAdd = this.props.formState === 'create'
    const showDeleteAndEdit =
      this.props.formState === 'edit' || this.props.formState === 'view'
    const showSubmit =
      this.props.formState === 'edit' || this.props.formState === 'create'
    if (this.props.initialValues && this.props.initialValues.strains) {
      this.props.initialValues.strains = this.props.initialValues.strains.map(
        strain => ({ label: strain.name, value: strain })
      )
    }
    return (
      <Formik
        enableReinitialize='true'
        initialValues={
          this.props.initialValues || {
            name: '',

            strain_type: '',
            mood: {
              Ecstatic: undefined,
              Happy: undefined,
              Creative: undefined,
              Alert: undefined,
              Emotional: undefined,
              Calm: undefined,
              Lazy: undefined,
              Sad: undefined
            },
            effect: {
              Aroused: undefined,
              Energetic: undefined,
              Tingly: undefined,
              Giggly: undefined,
              Rejuvenated: undefined,
              Focused: undefined,
              Relaxed: undefined,
              Sleepy: undefined
            },
            medical: {
              combat_fatigue: undefined,
              relieve_stress: undefined,
              reduce_anxiety: undefined,
              cure_nausea: undefined,
              remedy_stomach_pains: undefined,
              ease_pain: undefined,
              increase_appetite: undefined,
              insomnia_relief: undefined
            },
            taste: {
              Sweet: false,
              Citrus: false,
              Flowery: false,
              Earthy: false,
              Spicy: false,
              Bitter: false
            },
            popularity: undefined,
            short_desc: '',
            thc_percent: '',
            cbd_percent: '',
            kind_strain: [],
            strain_strain: [],
            cannabinoid_strain: [],
            terpene_strain: [],
            photos: []
          }
        }
        validationSchema={StrainSchema}
        onSubmit={this.props.handleSubmit}
        render={({ errors, touched, setFieldValue, values }) => {
          console.log(values)
          return (
            <Form className='form-block'>
              <div className='row d-flex justify-content-between'>
                <div className='col-xs-1 ml-1'>
                  <Link to={'/strain/home'}>
                    <i className='fa fa-chevron-left' aria-hidden='true' />
                  </Link>
                </div>
                <div className='col-xs-1 offset-md-10'>
                  {isView ? (
                    <Link to={`/strain/edit/${this.props.id}`}>
                      <i
                        className='fa fa-2x fa-pencil-square-o'
                        aria-hidden='true'
                      />
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='form-group'>
                <label>Strain Name <span className='req-ast'>*</span></label>
                <Field
                  type='text'
                  className='form-control'
                  name='name'
                  disabled={isView}
                />
                <ErrorMessage
                  name='name'
                  component='small'
                  className='form-text text-muted'
                />
              </div>
              <div className='form-group'>
                <label>Brand</label>
                <Async
                  loadOptions={this.loadBrandSelect.bind(this)}
                  isClearable
                  value={values.brand}
                  onChange={(brand, e) => {
                    if (e === 'clear') {
                      setFieldValue('brand', { label: '', value: { id: undefined } })
                    } else {
                      setFieldValue('brand', brand)
                    }
                  }}
                  isDisabled={isView}
                />
              </div>
              <div className='form-group'>
                <label>Strain Type</label>
                <Select
                  value={values.strain_type}
                  onChange={type => setFieldValue('strain_type', type)}
                  options={strainTypeOptions}
                  isDisabled={isView}
                  isClearable
                />
                <ErrorMessage
                  name='strain_type'
                  component='small'
                  className='form-text text-muted'
                />
              </div>
              <div className='form-group'>
                <h5>Mood</h5>
                <div className='form-group'>
                  <label>Ecstatic</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Ecstatic'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Ecstatic'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Happy</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Happy'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Happy'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Creative</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Creative'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Creative'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Alert</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Alert'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Alert'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Emotional</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Emotional'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Emotional'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Calm</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Calm'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Calm'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Lazy</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Lazy'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Lazy'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Sad</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='mood.Sad'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='mood.Sad'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
              </div>
              <div className='form-group'>
                <h5>Effect</h5>
                <div className='form-group'>
                  <label>Aroused</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Aroused'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Aroused'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Energetic</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Energetic'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Energetic'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Tingly</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Tingly'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Tingly'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Giggly</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Giggly'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Giggly'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Rejuvenated</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Rejuvenated'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Rejuvenated'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Focused</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Focused'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Focused'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Relaxed</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Relaxed'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Relaxed'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Sleepy</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='effect.Sleepy'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='effect.Sleepy'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
              </div>
              <div className='form-group'>
                <h5>Medical</h5>
                <div className='form-group'>
                  <label>Combat Fatigue</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.combat_fatigue'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.combat_fatigue'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Relieve Stress</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.relieve_stress'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.relieve_stress'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Reduce Anxiety</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.reduce_anxiety'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.reduce_anxiety'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Cure Nausea</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.cure_nausea'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.cure_nausea'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Remedy Stomach Pains</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.remedy_stomach_pains'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.remedy_stomach_pains'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Ease Pain</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.ease_pain'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.ease_pain'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Increase Appetite</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.increase_appetite'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.increase_appetite'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
                <div className='form-group'>
                  <label>Insomnia Relief</label>
                  <Field
                    type='number'
                    className='form-control'
                    name='medical.insomnia_relief'
                    disabled={isView}
                    placeholder='Enter Potency (e.g. 0.5)'
                  />
                  <ErrorMessage
                    name='medical.insomnia_relief'
                    component='small'
                    className='form-text text-muted'
                  />
                </div>
              </div>
              <div className='form-group'>
                <h5>Taste</h5>
                <div className='form-group'>
                  <label>Sweet</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Sweet'
                    checked={values.taste.Sweet}
                    disabled={isView}
                  />
                  <label>Citrus</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Citrus'
                    checked={values.taste.Citrus}
                    disabled={isView}
                  />
                  <label>Flowery</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Flowery'
                    checked={values.taste.Flowery}
                    disabled={isView}
                  />
                  <label>Earthy</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Earthy'
                    checked={values.taste.Earthy}
                    disabled={isView}
                  />
                  <label>Spicy</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Spicy'
                    checked={values.taste.Spicy}
                    disabled={isView}
                  />
                  <label>Bitter</label>
                  <Field
                    type='checkbox'
                    className='form-control'
                    name='taste.Bitter'
                    checked={values.taste.Bitter}
                    disabled={isView}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label>Popularity</label>
                <Field
                  type='number'
                  className='form-control'
                  name='popularity'
                  value={values.popularity || undefined}
                  disabled={isView}
                />
              </div>

              <div className='form-group'>
                <label>Short Desc</label>
                <Field
                  type='text'
                  className='form-control'
                  name='short_desc'
                  disabled={isView}
                />
              </div>
              <div className='form-group'>
                <label>THC Percent</label>
                <Field
                  type='text'
                  className='form-control'
                  name='thc_percent'
                  disabled={isView}
                />
              </div>
              <div className='form-group'>
                <label>CBD Percent</label>
                <Field
                  type='text'
                  className='form-control'
                  name='cbd_percent'
                  disabled={isView}
                />
              </div>
              <div className='form-group'>
                <label>Kinds</label>
                <Async
                  isClearable
                  loadOptions={this.loadKindSelect.bind(this, values.kind_strain)}
                  value={values.selectedKind}
                  onChange={(value, e) => {
                    const kindStrain = {}
                    if (value) {
                      kindStrain.name = value.label
                      kindStrain.strain_id = this.props.id
                      kindStrain.kind_id = value.value.id
                      kindStrain.new = true

                      values.kind_strain.push(kindStrain)
                      setFieldValue('kind_strain', values.kind_strain)
                      console.log('oi')
                      setFieldValue('selectedKind', '')
                    }
                  }}
                  isDisabled={isView}
                />
                <div className='row'>
                  {
                    values.kind_strain.length > 0
                      ? <table className='table'>
                        <thead>
                          <tr>
                            <th>Kind Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            values.kind_strain.map((kind, i) =>
                              <tr key={i}>
                                <td>{kind.name}</td>
                                <td>
                                  <button type='button' className='btn btn-link'
                                    onClick={() => {
                                      this.deleteKindStrain(kind, () => {
                                        values.kind_strain.splice(i, 1)
                                        setFieldValue('kind_strain', values.kind_strain)
                                      })
                                    }}
                                  >
                                    <i className='fa fa-trash' aria-hidden='true' />
                                  </button>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      : ''
                  }
                </div>
              </div>
              <div className='form-group'>
                <label>Parent Strains</label>
                <Async
                  isClearable
                  loadOptions={this.loadStrainSelect.bind(this, values.strain_strain)}
                  value={values.selectedStrain}
                  onChange={(value, e) => {
                    const strainStrain = {}
                    if (value) {
                      strainStrain.name = value.label
                      strainStrain.child_id = this.props.id
                      strainStrain.parent_id = value.value.id
                      strainStrain.new = true

                      values.strain_strain.push(strainStrain)
                      setFieldValue('strain_strain', values.strain_strain)
                      console.log('oi')
                      setFieldValue('selectedStrain', '')
                    }
                  }}
                  isDisabled={isView}
                />
                <div className='row'>
                  {
                    values.strain_strain.length > 0
                      ? <table className='table'>
                        <thead>
                          <tr>
                            <th>Parent Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            values.strain_strain.map((strain, i) =>
                              <tr key={i}>
                                <td>{strain.name}</td>
                                <td>
                                  <button type='button' className='btn btn-link'
                                    onClick={() => {
                                      this.deleteStrainStrain(strain, () => {
                                        values.strain_strain.splice(i, 1)
                                        setFieldValue('strain_strain', values.strain_strain)
                                      })
                                    }}
                                  >
                                    <i className='fa fa-trash' aria-hidden='true' />
                                  </button>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      : ''
                  }
                </div>
              </div>
              <div className='form-group'>
                <label>Cannabinoids</label>
                <Async
                  isClearable
                  loadOptions={this.loadCannabinoidSelect.bind(this, values.cannabinoid_strain)}
                  value={values.selectedCannabinoid}
                  onChange={(value, e) => {
                    const cannabinoidStrain = {}
                    if (value) {
                      cannabinoidStrain.name = value.value.name
                      cannabinoidStrain.strain_id = this.props.id
                      cannabinoidStrain.cannabinoid_id = value.value.id
                      cannabinoidStrain.new = true

                      values.cannabinoid_strain.push(cannabinoidStrain)
                      setFieldValue('cannabinoid_strain', values.cannabinoid_strain)
                      setFieldValue('selectedCannabinoid', '')
                    }
                  }}
                  isDisabled={isView}
                />
                <div className='row'>
                  {
                    values.cannabinoid_strain.length > 0
                      ? <table className='table'>
                        <thead>
                          <tr>
                            <th>Cannabinoid Name</th>
                            <th>Percentage</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            values.cannabinoid_strain.map((cannabinoid, i) =>
                              <tr key={i+cannabinoid.name}>
                                <td>{cannabinoid.name}</td>
                                <td>
                                  <input
                                    type='number'
                                    step='any'
                                    className='form-control'
                                    value={cannabinoid.percentage}
                                    onChange={(e) => this.updatePercentage(e, i, setFieldValue, values.cannabinoid_strain, 'cannabinoid_strain')}
                                    disabled={isView}
                                  />
                                </td>
                                <td>
                                  <button type='button' className='btn btn-link'
                                    onClick={() => {
                                      this.deleteCannabinoidStrain(cannabinoid, () => {
                                        values.cannabinoid_strain.splice(i, 1)
                                        setFieldValue('cannabinoid_strain', values.cannabinoid_strain)
                                      })
                                    }}
                                  >
                                    <i className='fa fa-trash' aria-hidden='true' />
                                  </button>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      : ''
                  }
                </div>
              </div>
              <div className='form-group'>
                <label>Terpenes</label>
                <Async
                  isClearable
                  loadOptions={this.loadTerpeneSelect.bind(this, values.terpene_strain)}
                  value={values.selectedTerpene}
                  onChange={(value, e) => {
                    const terpeneStrain = {}
                    if (value) {
                      terpeneStrain.name = value.value.name
                      terpeneStrain.strain_id = this.props.id
                      terpeneStrain.terpene_id = value.value.id
                      terpeneStrain.new = true

                      values.terpene_strain.push(terpeneStrain)
                      setFieldValue('terpene_strain', values.terpene_strain)
                      setFieldValue('selectedTerpene', '')
                    }
                  }}
                  isDisabled={isView}
                />
                <div className='row'>
                  {
                    values.terpene_strain.length > 0
                      ? <table className='table'>
                        <thead>
                          <tr>
                            <th>Terpene Name</th>
                            <th>Percentage</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            values.terpene_strain.map((terpene, i) =>
                              <tr key={i+terpene.name}>
                                <td>{terpene.name}</td>
                                <td>
                                  <input
                                    type='number'
                                    step='any'
                                    className='form-control'
                                    value={terpene.percentage}
                                    onChange={(e) => this.updatePercentage(e, i, setFieldValue, values.terpene_strain, 'terpene_strain')}
                                    disabled={isView}
                                  />
                                </td>
                                <td>
                                  <button type='button' className='btn btn-link'
                                    onClick={() => {
                                      this.deleteTerpeneStrain(terpene, () => {
                                        values.terpene_strain.splice(i, 1)
                                        setFieldValue('terpene_strain', values.terpene_strain)
                                      })
                                    }}
                                  >
                                    <i className='fa fa-trash' aria-hidden='true' />
                                  </button>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      : ''
                  }
                </div>
              </div>
              <div className='form-group'>
                <label>Photos</label>
                <input type='file' className='form-control' onChange={e => this.handleFileUpload(e, values, setFieldValue)} disabled={!isEdit} />
                {
                  !isAdd
                    ? values.photos.map((p, i) => <div key={i + Date.now()}>
                      <div className='embed-responsive embed-responsive-1by1'>
                        <img className='embed-responsive-item' src={p.file} />
                      </div>
                      {
                        p.new ? <div className='progress'>
                          <div
                            className={p.percent === 1 ? 'progress-bar bg-success' : 'progress-bar'}
                            role='progressbar'
                            style={{ width: parseInt(p.percent * 100) + '%' }}
                            aria-valuenow={parseInt(p.percent * 100) + '%'}
                            aria-valuemin='0' aria-valuemax='100'>
                            {p.percent === 1 ? 'Ready to upload!' : parseInt(p.percent * 100) + '%'}
                          </div>
                        </div> : ''
                      }
                      <a className='btn btn-danger' onClick={() => {
                        this.deletePhoto(p, () => {
                          values.photos.splice(i, 1)
                          setFieldValue('photos', values.photos)
                        })
                      }}>
                        Delete Above Photo
                      </a>
                    </div>)
                    : <p>Please save the Strain to add Photos</p>
                }
              </div>
              {showSubmit ? <button className='btn btn-primary ml-auto p-2' type='submit'>Submit</button> : ''}
              {showDeleteAndEdit ? (
                <button
                  type='button'
                  onClick={this.delete.bind(this)}
                  className='btn btn-danger ml-auto p-2'
                >
                  Delete
                </button>
              ) : (
                ''
              )}
            </Form>
          )
        }}
      />
    )
  }
}

export default StrainForm
