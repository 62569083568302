class AuthHelper {
  static get TOKEN_STORAGE_KEY () {
    return 'token'
  }
  static isLoggedIn () {
    const token = AuthHelper.getToken()
    console.log('auth status', token)
    return token !== null
  }
  static setToken (token) {
    return window.localStorage.setItem(AuthHelper.TOKEN_STORAGE_KEY, token)
  }
  static getToken () {
    return window.localStorage.getItem(AuthHelper.TOKEN_STORAGE_KEY)
  }
  static logout (history) {
    window.localStorage.removeItem(AuthHelper.TOKEN_STORAGE_KEY)
    if (history) {
      history.push('/login')
    } else {
      window.location.assign('/login')
    }
  }
}

export default AuthHelper
