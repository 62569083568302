import React, { Component } from 'react'
import DispensaryForm from './dispensary.form'
import DispensaryModel from './dispensary.model'
class DispensaryCreate extends Component {
  render () {
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <DispensaryForm
            handleSubmit={values => DispensaryModel.create(values, this.props.history)}
            formState='create' history={this.props.history}
          />
        </div>

      </div>
    )
  }
}

export default DispensaryCreate
