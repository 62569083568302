import _ from 'lodash'
import FormHelpers from '../helpers/form.helpers'
import AuthHTTP from '../helpers/auth.http'

const { clean, stripEmptyFields, asCallback } = FormHelpers

class StrainModel {
  static _prepareStrainForAPI (values) {
    console.log('sup')
    console.log(values)
    if (values.strain_type) values.strain_type = values.strain_type.value
    if (values.brand) {
      values.brand_id = values.brand.value.id
    } else {
      values.brand_id = null
    }
    const photos = values.photos.filter(p => p.new).map(p => p.file)
    const strain = _.omit(values, ['effect', 'mood', 'medical', 'taste', 'brand', 'selectedKind', 'selectedStrain', 'selectedCannabinoid', 'selectedTerpene', 'photos'])
    clean(values.effect)
    clean(values.mood)
    clean(values.medical)
    // special taste handler
    for (let key in values.taste) {
      values.taste[key] = values.taste[key] ? 1 : null
    }
    clean(values.taste)
    strain.photos = photos
    strain.effect = JSON.stringify(values.effect)
    strain.mood = JSON.stringify(values.mood)
    strain.taste = JSON.stringify(values.taste)
    strain.medical = {}
    for (const key in values.medical) {
      const words = key.split('_')
      const upperWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      const finalKey = upperWords.join(' ')
      strain.medical[finalKey] = values.medical[key]
    }
    console.log(strain.medical)
    strain.medical = JSON.stringify(strain.medical)
    stripEmptyFields(strain)
    console.log(strain)
    return strain
  }
  static async getAndPrepareStrainForView (id) {
    const [err, resp] = await asCallback(AuthHTTP.get(`/api/strain/${id}`))
    if (err) {
      console.error(err)
      return null
    }
    if (resp.data.rows) {
      // set keys to not be null
      const strain = resp.data.rows[0]
      for (let key in strain) {
        if (strain[key] === null) {
          strain[key] = undefined
        }
      }
      if (strain.brand) {
        strain.brand = { label: strain.brand.name, value: strain.brand }
      } else {
        strain.brand = undefined
      }
      if (!strain.taste) {
        strain.taste = {}
      }
      if (!strain.kind_strain) {
        strain.kind_strain = []
      }
      if (!strain.strain_strain) {
        strain.strain_strain = []
      }
      if (!strain.cannabinoid_strain) {
        strain.cannabinoid_strain = []
      }
      if (!strain.terpene_strain) {
        strain.terpene_strain = []
      }
      if (strain.strain_type) {
        strain.strain_type = { label: strain.strain_type, value: strain.strain_type }
      }
      if (!strain.photos) {
        strain.photos = []
      }
      // medical special handling
      let newMed = {}
      for (const key in strain.medical) {
        const words = key.split(' ')
        const upperWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1))
        const finalKey = upperWords.join('_')
        newMed[finalKey] = strain.medical[key]
      }
      strain.medical = newMed
      for (let key in strain.taste) {
        strain.taste[key] = Boolean(strain.taste[key] || strain.taste[key] === 1)
        console.log('hello', strain.taste[key], (strain.taste[key] || strain.taste[key] === 1))
      }
      console.log(strain)
      return strain
    }
  }
  static create (values, history) {
    const strain = StrainModel._prepareStrainForAPI(values)
    AuthHTTP
      .post('/api/strain', strain)
      .then(resp => {
        history.push(
          `/strain/view/${resp.data.createdId}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  static edit (values, id, history) {
    const strain = StrainModel._prepareStrainForAPI(values)
    AuthHTTP
      .put(`/api/strain/${id}`, strain)
      .then(resp => {
        history.push(
          `/strain/view/${id}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
}
export default StrainModel
