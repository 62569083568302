import React from 'react'
import { withRouter, Link } from 'react-router-dom'
const SideBar = props => {
  const currentRoot = props.location.pathname.split('/')[1]
  return (
    <div className={props.showSidebar ? 'sidebar force-show' : 'sidebar'}>
      <nav>
        {console.log(props)}
        <ul className='sidebar-nav'>
          <li className='sidebar-header'>Main Navigation</li>
          {/* TODO refactor links into a component */}
          <li className={currentRoot === 'strain' ? 'sidebar-item sidebar-active' : 'sidebar-item'}>
            <Link href='#' className='sidebar-link' to='/strain/home'>
              <i className='fa fa-leaf sidebar-icon' aria-hidden='true' />
              <span>Strains</span>
            </Link>
          </li>
          <li className={currentRoot === 'dispensary' ? 'sidebar-item sidebar-active' : 'sidebar-item'}>
            <Link href='#' className='sidebar-link' to='/dispensary/home'>
              <i className='fa fa-flag sidebar-icon' aria-hidden='true' />
              <span>Dispensaries</span>
            </Link>
          </li>
          <li className={currentRoot === 'brand' ? 'sidebar-item sidebar-active' : 'sidebar-item'}>
            <Link href='#' className='sidebar-link' to={'/brand/home'}>
              <i className='fa fa-th-list sidebar-icon' aria-hidden='true' />
              <span>Brands</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
export default withRouter(SideBar)
