import FormHelpers from '../helpers/form.helpers'
import AuthHTTP from '../helpers/auth.http'

const { clean, stripEmptyFields, asCallback } = FormHelpers

class BrandModel {
  static _prepareForAPI (values) {
    const postableBrand = JSON.parse(JSON.stringify(values))
    console.log(values)
    postableBrand.strain_ids = values.strains.map(strain => strain.value.id)
    const photos = values.photos.filter(p => p.new).map(p => p.file)

    delete postableBrand.strains
    delete postableBrand.selected
    postableBrand.photos = photos
    stripEmptyFields(postableBrand)
    return postableBrand
  }
  static async getAndPrepareForView (id) {
    const [err, resp] = await asCallback(AuthHTTP.get(`/api/brand/${id}`))
    if (err) {
      console.error(err)
      return null
    }
    if (resp.data.rows) {
      // set keys to not be null
      const brand = resp.data.rows[0]
      for (let key in brand) {
        if (brand[key] === null) {
          brand[key] = undefined
        }
      }
      if (!brand.photos) {
        brand.photos = []
      }

      return brand
    }
  }
  static create (values, history) {
    const brand = BrandModel._prepareForAPI(values)
    console.log(brand)
    AuthHTTP
      .post('/api/brand', brand)
      .then(resp => {
        history.push(
          `/brand/view/${resp.data.createdId}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  static edit (values, id, history) {
    console.log(values)
    const brand = BrandModel._prepareForAPI(values)
    AuthHTTP
      .put(`/api/brand/${id}`, brand)
      .then(resp => {
        history.push(
          `/brand/view/${id}`
        )
        console.log(resp)
      })
      .catch(err => {
        console.error(err.response)
      })
  }
}
export default BrandModel
