import React, { Component } from 'react'
import BrandSchema from './brand.schema'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import Async from 'react-select/lib/Async'

import AuthHTTP from '../helpers/auth.http'

class BrandForm extends Component {
  async loadStrainSelect (selected, i) {
    console.log(i)
    console.log(selected)
    const resp = await AuthHTTP.get(`/api/strain?col=name&q=${i || ''}`)
    const selectedIds = selected.map(e => e.value.id)
    console.log(selectedIds)
    return resp.data.map(elem => ({ label: elem.name, value: elem })).filter(elem => !selectedIds.includes(elem.value.id))
  }
  delete (e) {
    e.preventDefault()
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (!doIt) return
    AuthHTTP.delete(`/api/brand/${this.props.id}`)
      .then(resp => {
        console.log(resp)
        this.props.history.push('/brand/home')
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  updateStrainBrandID (strain, updateStrains) {
    strain.brand_id = null
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      AuthHTTP.put(`/api/strain/${strain.id}`, strain)
        .then(resp => updateStrains())
        .catch(err => console.error(err.response))
    }

  }
  handleFileUpload (e, values, setFieldValue) {
    const mainEvent = e
    mainEvent.persist()
    if (e.target.files[0]) {
      const index = values.photos.length
      console.log(e.target.files[0])
      values.photos.push({
        new: true,
        file: '',
        fileName: e.target.files[0].name,
        percent: 0
      })
      const fr = new FileReader()
      fr.onload = (e) => {
        values.photos[index].file = e.target.result
        mainEvent.target.value = null
        mainEvent.target.files = null
        setFieldValue('photos', values.photos)
      }
      fr.onprogress = (e) => {
        values.photos[index].percent = e.loaded / e.total
        setFieldValue('photos', values.photos)
      }
      fr.onerror = (e) => {
        console.log(e)
      }
      fr.readAsDataURL(e.target.files[0])
    }
  }
  deletePhoto (value, removeFromForm) {
    console.log(value)
    const doIt = window.confirm('This action cannot be undone. Are you sure?')
    if (doIt) {
      console.log('hey')
      if (value.id) {
        AuthHTTP.delete(`/api/photo/${value.id}`)
          .then(resp => {
            console.log(resp)
            removeFromForm()
          })
          .catch(err => console.error(err.response))
      } else {
        removeFromForm()
      }
    }
  }
  render () {
    const isView = this.props.formState === 'view'
    const isEdit = this.props.formState === 'edit'
    const isAdd = this.props.formState === 'create'
    const showDeleteAndEdit = this.props.formState === 'edit' || this.props.formState === 'view'
    const showSubmit = this.props.formState === 'edit' || this.props.formState === 'create'
    if (this.props.initialValues && this.props.initialValues.strains) {
      this.props.initialValues.strains = this.props.initialValues.strains.map(strain => ({label: strain.name, value: strain}))
    }
    return (<Formik
      enableReinitialize='true'
      initialValues={this.props.initialValues || {
        name: '',
        rating: undefined,
        popularity: undefined,
        short_desc: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
        email: '',
        website: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        license: '',
        brand_priority: undefined,
        strains: [],
        photos: []
      }}
      validationSchema={BrandSchema}
      onSubmit={this.props.handleSubmit}
      render={({ errors, touched, setFieldValue, values }) => (
        <Form className='form-block'>
          <div className='row d-flex justify-content-between'>
            <div className='col-xs-1 ml-1'>
              <Link to={'/brand/home'}><i className='fa fa-chevron-left' aria-hidden='true' /></Link>
            </div>
            <div className='col-xs-1 offset-md-10'>
              { isView ? <Link to={`/brand/edit/${this.props.id}`}><i className='fa fa-2x fa-pencil-square-o' aria-hidden='true' /></Link> : '' }
            </div>
          </div>
          <div className='form-group'>
            <label>Brand Name <span className='req-ast'>*</span></label>
            <Field type='text' className='form-control' name='name' disabled={isView} />
            <ErrorMessage
              name='name'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand Rating</label>
            <Field type='text' className='form-control' name='rating'disabled={isView} />
            <ErrorMessage
              name='rating'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand Popularity</label>
            <Field type='text' className='form-control' name='popularity' disabled={isView} />
            <ErrorMessage
              name='popularity'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand Description</label>
            <Field type='text' className='form-control' name='short_desc' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Address</label>
            <Field type='text' className='form-control' name='address' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand City</label>
            <Field type='text' className='form-control' name='city' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand State</label>
            <Field type='text' className='form-control' name='state' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Zipcode</label>
            <Field type='text' className='form-control' name='zipcode' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Phone Number</label>
            <Field type='text' className='form-control' name='phone' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Email</label>
            <Field type='email' className='form-control' name='email' disabled={isView} />
            <ErrorMessage
              name='email'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand Website</label>
            <Field type='text' className='form-control' name='website' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Contact Name</label>
            <Field type='text' className='form-control' name='contact_name' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Contact Phone</label>
            <Field type='text' className='form-control' name='contact_phone' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Priority</label>
            <Field type='number' className='form-control' name='brand_priority' disabled={isView} />
          </div>

          <div className='form-group'>
            <label>Brand Contact Email</label>
            <Field type='email' className='form-control' name='contact_email' disabled={isView} />
            <ErrorMessage
              name='contact_email'
              component='small'
              className='form-text text-muted'
            />
          </div>

          <div className='form-group'>
            <label>Brand License</label>
            <Field type='text' className='form-control' name='license' disabled={isView} />
          </div>
          <div className='form-group'>
            <label>Strains</label>
            <Async
              isClearable
              loadOptions={this.loadStrainSelect.bind(this, values.strains)}
              value={values.selected}
              onChange={(strain, e) => {
                values.strains.push(strain)
                setFieldValue('strains', values.strains)
                setFieldValue('selected', null)
              }}
              isDisabled={isView}
            />
          </div>
          <div className='form-group'>
            <div className='row'>
              {
                values.strains.length > 0
                  ? <table className='table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        values.strains.map((strain, i) =>
                          <tr key={strain.value.id}>
                            <td>{strain.value.name}</td>
                            <td>
                              <button type='button' className='btn btn-link' onClick={() => {
                                this.updateStrainBrandID(strain.value, () => {
                                  values.strains.splice(i, 1)
                                  setFieldValue('strains', values.strains)
                                })
                              }}>
                                <i className='fa fa-trash' aria-hidden='true' />
                              </button>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                  : ''
              }
            </div>
          </div>
          <div className='form-group'>
            <label>Photos</label>
            <input type='file' className='form-control' onChange={e => this.handleFileUpload(e, values, setFieldValue)} disabled={!isEdit} />
            {
              !isAdd
                ? values.photos.map((p, i) => <div key={i + Date.now()}>
                  <div className='embed-responsive embed-responsive-1by1'>
                    <img className='embed-responsive-item' src={p.file} />
                  </div>
                  {
                    p.new ? <div className='progress'>
                      <div
                        className={p.percent === 1 ? 'progress-bar bg-success' : 'progress-bar'}
                        role='progressbar'
                        style={{ width: parseInt(p.percent * 100) + '%' }}
                        aria-valuenow={parseInt(p.percent * 100) + '%'}
                        aria-valuemin='0' aria-valuemax='100'>
                        {p.percent === 1 ? 'Ready to upload!' : parseInt(p.percent * 100) + '%'}
                      </div>
                    </div> : ''
                  }
                  <a className='btn btn-danger' onClick={() => {
                    this.deletePhoto(p, () => {
                      values.photos.splice(i, 1)
                      setFieldValue('photos', values.photos)
                    })
                  }}>
                    Delete Above Photo
                  </a>
                </div>)
                : <p>Please save the Brand to add Photos</p>
            }
          </div>

          {showSubmit ? <button className='btn btn-primary ml-auto p-2' type='submit' >Submit</button> : ''}
          {showDeleteAndEdit ? <button type='button' onClick={this.delete.bind(this)} className='btn btn-danger ml-auto p-2'>Delete</button> : ''}
        </Form>
      )}
    />)
  }
}

export default BrandForm
