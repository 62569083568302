import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Paginator from '../Paginator'
import CSVUploadModal from '../_common/csv.upload.modal'
import AuthHTTP from '../helpers/auth.http'

class StrainHome extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      rows: [],
      renderedRows: [],
      nameFilter: '',
      count: 0,
      paginationList: '',
      modal: false,
      scvErr: ''
    }
  }
  handleChange (event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    })
  }
  uploadCSV (csv) {
    AuthHTTP.post('/api/strain/csv', csv)
      .then(resp => {
        console.log(resp)
        this.getStrains()
        this.toggleModal()
      })
      .catch(err => {
        console.error(err)
        let msg = ''
        console.error(err.response.data)
        // TODO wonky handling fix
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message.split('-')[1] === ' duplicate key value violates unique constraint "index_tbl_strain_on_name"') {
            msg = 'Duplicate names found in CSV.'
          }
          this.setState({csvErr: msg || err.response.data.message})
        }
      })
  }
  getStrains() {
    const page = this.state.page || 1
    AuthHTTP.get(`/api/strain/paginated/${page}`)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  componentDidMount () {
    this.getStrains()
  }
  renderRows (rows) {
    return rows.map(row => {
      return (
        <tr key={row.id}>
          <td>{row.id}</td>
          <td>{row.name}</td>
          <td>{row.kinds[0] || ''}</td>
          <td>{row.strain_type}</td>
          <td>{row.popularity}</td>
          <td style={{ width: '61px' }}>
            <Link to={`/strain/view/${row.id}`}> <i className='fa fa-eye' aria-hidden='true' /></Link>
            <Link to={`/strain/edit/${row.id}`}> <i className='fa fa-pencil' aria-hidden='true' /></Link>
          </td>
        </tr>
      )
    })
  }
  retrieveDataAndUpdateState (page = 1) {
    let url = `/api/strain/paginated/${page}`
    if (this.state.nameFilter) url += `/name/${this.state.nameFilter}`
    AuthHTTP.get(url)
      .then(resp => {
        console.log(resp)
        this.setState({
          page: page,
          rows: resp.data.rows,
          count: resp.data.count
        })
      })
      .catch(err => {
        console.error(err.response)
      })
  }
  handleSearch (e) {
    if (e) e.preventDefault()
    this.retrieveDataAndUpdateState()
  }

  changePage (page) {
    this.retrieveDataAndUpdateState(page)
  }
  toggleModal () {
    this.setState({ modal: !this.state.modal })
  }
  render () {
    const paginator = <Paginator page={this.state.page} count={this.state.count} changeHandler={this.changePage.bind(this)} />
    const rowsToShow = this.renderRows(this.state.rows)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2 home-block'>
          <div className='row d-flex justify-content-between'>
            <div className='col-md-5'>
              <form>
                <div className='input-group mb-3'>
                  <input type='text' className='form-control' placeholder={'Search by name...'} name='nameFilter' onChange={this.handleChange.bind(this)}/>
                  <div className='input-group-append'>
                    <button className='btn btn-outline-secondary' type='submit' onClick={this.handleSearch.bind(this)}>Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-md-5'>
              <div className='row'>
                <Link className='btn btn-primary btn-sm ' to='/strain/create'>Create Strain</Link>
                <a className='btn btn-primary btn-sm' href='/StrainCreate.csv'>Download CSV</a>
                <button className='btn btn-primary btn-sm' onClick={this.toggleModal.bind(this)}>Upload CSV</button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2'>
              {paginator}
            </div>
          </div>
          <div className={'row'}>
            <table className='table table-bordered text-center'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Kind</th>
                  <th>Type</th>
                  <th>Popularity</th>
                  <th>{'\u00A0'}</th>
                </tr>
              </thead>
              <tbody>
                { rowsToShow.length ? rowsToShow : <tr><td>No data to show</td></tr>}
              </tbody>
            </table>
          </div>
          <div className='row'>
            <div className='col-md-8 offset-2 text-center'>
              {paginator}
            </div>
          </div>
        </div>
        <CSVUploadModal toggle={this.toggleModal.bind(this)} open={this.state.modal} uploadCSV={this.uploadCSV.bind(this)} err={this.state.csvErr} />
      </div>
    )
  }
}

export default StrainHome
