import React from 'react'
import _ from 'lodash'
const helpers = {
  asCallback (promise) {
    return promise.then(data => {
      return [null, data]
    }).catch(err => [err])
  },
  pagination: function (currentPageInput, totalPages, handlerFunc){
    let currentPage = currentPageInput, // input
      range = _.clamp(totalPages, 1, 5),  // amount of links displayed
      start = 1;  // default
    const paging = []
    paging.push(
      <li className='page-item' key={0}>
        <button className='btn page-link' onClick={ () => handlerFunc((Number(currentPageInput) - 1))} disabled={!(currentPageInput > 1)}><i className='fa fa-fast-backward' aria-hidden='true'/> Previous</button>
      </li>)

    // Don't use negative values, force start at 1
    if (currentPage < (range / 2) + 1 ) {
      start = 1;

      // Don't go beyond the last page
    } else if (currentPage >= (totalPages - (range / 2) )) {
      start = Math.floor(totalPages - range + 1);

    } else {
      start = (currentPage - Math.floor(range / 2));
    }

    for (let i = start; i <= ((start + range) - 1); i++) {
      if (i === currentPage) {
        paging.push(
          <li className='page-item active' key={i}>
            <button className='page-link' onClick={() => handlerFunc(i)}>{i} <span className='sr-only'>(current)</span></button>
          </li>
        ); // add brackets to indicate current page
      } else {
        paging.push(
          <li className='page-item' key={i}><button className='page-link' onClick={() => handlerFunc(i)} key={i}>{i}</button></li>
        )
      }
    }

    paging.push(
      <li className='page-item' key={(start + range)}>
        <button className='btn page-link' onClick={() => handlerFunc((Number(currentPageInput) + 1))} disabled={!(currentPageInput < totalPages)}>Next <i className='fa fa-fast-forward' aria-hidden='true'/></button>
      </li>)

    return paging
  },
  clean: function(obj) {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName]
      }
    }
  },
  stripEmptyFields: function(fields) {
    for (let key in fields) {
      fields[key] = fields[key] || null
    }
  }
}
export const clean = helpers.clean
export const pagination = helpers.pagination
export const stripEmptyFields = helpers.stripEmptyFields
export default helpers
