import * as Yup from 'yup'

const StrainSchema = Yup.object().shape({
  name: Yup.string('Name is Required').required('Name is required'),
  mood: Yup.object().shape({
    ecstatic: Yup.number('Must be a valid number'),
    happy: Yup.number('Must be a valid number'),
    creative: Yup.number('Must be a valid number'),
    alert: Yup.number('Must be a valid number'),
    emotional: Yup.number('Must be a valid number'),
    calm: Yup.number('Must be a valid number'),
    lazy: Yup.number('Must be a valid number'),
    sad: Yup.number('Must be a valid number')
  }),
  effect: Yup.object().shape({
    aroused: Yup.number('Must be a valid number'),
    energetic: Yup.number('Must be a valid number'),
    tingly: Yup.number('Must be a valid number'),
    giggly: Yup.number('Must be a valid number'),
    rejuvenated: Yup.number('Must be a valid number'),
    focused: Yup.number('Must be a valid number'),
    relaxed: Yup.number('Must be a valid number'),
    sleepy: Yup.number('Must be a valid number')
  }),
  // medical: Yup.object().shape({
  //   combat_fatigue: Yup.number('Must be a valid number'),
  //   relieve_stress: Yup.number('Must be a valid number'),
  //   reduce_anxiety: Yup.number('Must be a valid number'),
  //   cure_nausea: Yup.number('Must be a valid number'),
  //   remedy_stomach_pains: Yup.number('Must be a valid number'),
  //   ease_pain: Yup.number('Must be a valid number'),
  //   increase_appetite: Yup.number('Must be a valid number'),
  //   insomnia_relief: Yup.number('Must be a valid number')
  // }),
  taste: Yup.object().shape({
    sweet: Yup.bool(),
    citrus: Yup.bool(),
    flowery: Yup.bool(),
    earthy: Yup.bool(),
    spicy: Yup.bool(),
    bitter: Yup.bool()
  }),
  popularity: Yup.number(),
  short_desc: Yup.string(),
  thc_percent: Yup.string(),
  cbd_percent: Yup.string()
})

export default StrainSchema
