import React, { Component } from 'react'
import BrandForm from './strain.form'
import StrainModel from './strain.model'

class StrainView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      strain: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    const strain = await StrainModel.getAndPrepareStrainForView(id)
    console.log(strain, 'now')
    this.setState({ strain: strain })
  }

  render () {
    console.log(this.state.brand)
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <BrandForm initialValues={this.state.strain} formState='view' id={this.props.match.params.id} history={this.props.history} />
        </div>
      </div>
    )
  }
}

export default StrainView
