import React from 'react'
import {Link} from 'react-router-dom'
export default () => <div>
  <div className='jumbotron text-center'>
    <h1 className='display-4'>404 page not found</h1>
    <p className='lead'>If you feel this is an error, please try again</p>
    <hr className='my-4' />
      <p>Otherwise click below to be redirected to the home page</p>
      <p className='lead'>
        <Link className='btn btn-primary btn-lg' to='/' role='button'>Home</Link>
      </p>
  </div>
</div>
