import React, { Component } from 'react'
import StrainForm from './strain.form'
import StrainModel from './strain.model'
import _ from 'lodash'


class StrainEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      strain: null
    }
  }
  async componentWillMount () {
    const id = this.props.match.params.id
    StrainModel.getAndPrepareStrainForView(id)
      .then(strain => {
        this.setState({ strain: strain })
      })
      .catch(err => {
        console.error(err.response)
      })
  }

  render () {
    const id = this.props.match.params.id
    return (
      <div className='container-fluid'>
        <div className='col-md-8 offset-md-2'>
          <StrainForm
            handleSubmit={values => StrainModel.edit(values, id, this.props.history)}
            initialValues={this.state.strain}
            formState='edit' id={id}
            history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default StrainEdit
